import Link from 'next/link';

import { Box, chakra, Flex, Heading, Text, useToken } from '@chakra-ui/react';

import { ContentContainer } from '~/components/container';
import { Glyph } from '~/components/glyph';

const schoolCards = [
  {
    label: 'Data Science',
    glyph: 'school-data-sci-one',
    path: '/school/data-science',
  },
  {
    label: 'Programming & Development',
    glyph: 'school-programming-one',
    path: '/school/programming',
  },
  {
    label: 'Artificial Intelligence',
    glyph: 'school-ai-one',
    path: '/school/artificial-intelligence',
  },
  {
    label: 'Business',
    glyph: 'school-business-one',
    path: '/school/business',
  },
  {
    label: 'Autonomous Systems',
    glyph: 'school-autonomous-sys-one',
    path: '/school/autonomous-systems',
  },
  {
    label: 'Product Management',
    glyph: 'school-product-mgt-one',
    path: '/school/product-management',
  },
  {
    label: 'Cloud Computing',
    glyph: 'school-cloud-computing-one',
    path: '/school/cloud-computing',
  },
] as const;

export function SchoolCardsSection({ onCardClick }: { onCardClick?: (schoolTitle: string) => void }) {
  const [seaFoam] = useToken('colors', ['accent.sea-foam.500']);
  return (
    <Box
      as='section'
      bgColor='white'
      paddingTop={{
        base: '32px',
        md: '56px',
      }}
    >
      <ContentContainer paddingEnd={{ base: 4, xl: 0 }} paddingStart={{ base: 4, xl: 0 }}>
        <Heading as='h2' mb={{ base: 4, xl: 6 }} size='h4'>
          Explore courses by job function
        </Heading>
        <Box aria-label='schools navigation' as='nav'>
          <Box
            borderBottom={{ md: '1px solid #F0F5FF4D' }}
            borderLeft={{ md: '1px solid #F0F5FF4D' }}
            borderRight={{ md: '1px solid #F0F5FF4D' }}
            borderTop='1px solid #F0F5FF4D'
            position='relative'
            borderRadius={{
              md: '12px',
            }}
          >
            <Flex
              as='ul'
              gap={2}
              listStyleType='none'
              marginX={{ base: '-16px', xl: 'unset' }}
              overflow='auto'
              position='relative'
              paddingEnd={{
                base: '16px',
                xl: 'unset',
              }}
              paddingStart={{
                base: '16px',
                xl: 'unset',
              }}
            >
              {schoolCards.map((schoolCard) => {
                return (
                  <chakra.li key={schoolCard.label} display='flex' flexBasis='149px' flexGrow={1} flexShrink={0}>
                    <Flex
                      alignItems='center'
                      as={Link}
                      bg='accent.sky.100'
                      borderRadius='base'
                      flex={1}
                      flexDirection='column'
                      href={schoolCard.path}
                      justifyContent='center'
                      minWidth='144px'
                      overflow='auto'
                      p='16px'
                      width={0}
                      _hover={{
                        boxShadow: `inset 0 0 0 3px ${seaFoam}`,
                      }}
                      onClick={() => onCardClick?.(schoolCard.label)}
                    >
                      <Glyph name={schoolCard.glyph} />
                      <Text color='black' textAlign='center'>
                        {schoolCard.label}
                      </Text>
                    </Flex>
                  </chakra.li>
                );
              })}
            </Flex>
          </Box>
        </Box>
      </ContentContainer>
    </Box>
  );
}
