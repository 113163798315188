import { PropsWithChildren } from 'react';

import { Box, BoxProps } from '@chakra-ui/react';

const baseCardStyles = {
  bg: 'white',
  border: 'solid 1px',
  borderColor: 'gray.300',
  borderRadius: 'base',
};

export function SectionCard(props: PropsWithChildren<BoxProps>) {
  const { children, ...boxProps } = props;

  return (
    <Box
      as='section'
      margin='auto'
      maxWidth='736px'
      p={{ base: '40px 16px', md: '64px 96px' }}
      {...baseCardStyles}
      {...boxProps}
    >
      {children}
    </Box>
  );
}

export function Card(props: PropsWithChildren<BoxProps>) {
  const { children, ...boxProps } = props;

  return (
    <Box {...baseCardStyles} {...boxProps}>
      {children}
    </Box>
  );
}
