import Image from 'next/image';

import { Box, Heading } from '@chakra-ui/react';

import { ButtonLink } from '~/components/button-link';
import { Card } from '~/components/card';
import { ContentContainer } from '~/components/container';
import { Markdown } from '~/components/markdown';
import { trackEvent } from '~/features/analytics/track-event';

export function GovernmentBannerSection({ title, description }: { title: string; description: string }) {
  return (
    <Box as='section'>
      <ContentContainer>
        <Card padding='24px 40px' position='relative'>
          <Box
            display={{
              base: 'none',
              xl: 'block',
            }}
          >
            <Image
              alt=''
              height={559}
              src='/images/backgrounds/blue-lines.png'
              width={456}
              style={{
                top: '0px',
                bottom: '0px',
                right: '0px',
                position: 'absolute',
                height: '100%',
                objectFit: 'cover',
                opacity: 0.2,
              }}
            />
          </Box>
          <Heading as='h2' size='h6'>
            {title}
          </Heading>
          <Box h='8px' />
          <Markdown markdownText={description} />
          <ButtonLink
            buttonProps={{
              variant: 'link',
              'aria-label': 'Learn more about Udacity for Government',
              onClick: (e) => {
                trackEvent({
                  name: 'Homepage CTA Clicked',
                  type: 'click',
                  label: 'Learn More',
                });
              },
            }}
            linkProps={{
              href: '/government/overview',
            }}
          >
            Learn More
          </ButtonLink>
        </Card>
      </ContentContainer>
    </Box>
  );
}
