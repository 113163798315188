import { ReactNode } from 'react';
import dynamic from 'next/dynamic';

import { Box } from '@chakra-ui/react';
import { ArrowRightIcon } from '@udacity/chakra-uds-icons';

import { ShapesBanner } from '~/components/shapes-banner';
import { Space } from '~/components/space';
import { TemplateButton } from '~/components/template-button';
import { Testimonials } from '~/components/testimonials';
import { ThirdPartyReviews } from '~/components/third-party-reviews';
import { trackEvent } from '~/features/analytics/track-event';
import { CatalogItem } from '~/features/catalog/models/catalog-item';
import { SchoolCardsSection } from '~/features/experiments/ab_hp_value_test/school-cards-section';
import { useExperiment } from '~/features/experiments/hooks/use-experiment';
import { EnterpriseContentBlockSection } from '~/features/page-template/templates/home/components/enterprise-section';

import { HomeTemplate } from '../model';

import { AnimatedContentBlockSection } from './animated-content-block-section';
import { DecorativeWave } from './decorative-wave';
import { GovernmentBannerSection } from './government-banner-section';
import { HeroSection } from './hero-section';
import { Recommendations } from './recommendations';

const HeroSectionChallenger = dynamic<{}>(() =>
  import('~/features/experiments/ab_hp_value_test/hero-section').then((mod) => mod.HeroSectionChallenger)
);

export type ProgramCollectionWithCatalogData = {
  title: string;
  programs: CatalogItem[];
};

export function Home({
  homeTemplate,
  newPrograms,
  programCollections,
  isFreeTrialPage = false,
  heroSlot = null,
  heroBottomSlot = null,
}: {
  homeTemplate: HomeTemplate;
  newPrograms: ProgramCollectionWithCatalogData;
  programCollections: ProgramCollectionWithCatalogData[];
  isFreeTrialPage?: boolean;
  heroSlot?: ReactNode;
  heroBottomSlot?: ReactNode;
}) {
  const experiment = useExperiment();
  const {
    pageTitle,
    testimonials,
    thirdPartyReviews,
    blueCardContentBlockTitle,
    blueCardContentBlockCaption,
    blueCardContentBlockDescription,
    blueCardContentBlockImage,
    blueCardContentBlockPrimaryCta,
    blueCardContentBlockSecondaryCta,
    animatedContentBlockTitle,
    animatedContentBlockDescription,
    governmentBannerTitle,
    governmentBannerDescription,
  } = homeTemplate;

  const hero = heroSlot || (
    <HeroSection
      isFreeTrialOn={isFreeTrialPage}
      title={pageTitle}
      onSchoolCardClick={(schoolTitle) =>
        trackEvent({
          name: 'Homepage CTA Clicked',
          type: 'click',
          label: 'school_card_clicked',
          value: schoolTitle,
        })
      }
    />
  );

  return (
    <>
      {experiment?.ab_hp_value_test === 'variant_b' ? <HeroSectionChallenger /> : hero}
      {experiment?.ab_hp_value_test === 'variant_b' && <SchoolCardsSection />}
      {heroBottomSlot}
      <Box
        as='section'
        bgColor={experiment?.ab_hp_value_test === 'variant_b' ? 'white' : 'unset'}
        overflow='hidden'
        position='relative'
        minHeight={{
          base: '1694px',
          md: '1692px',
        }}
      >
        <DecorativeWave color='blue' />
        <DecorativeWave color='green' />
        <Space />
        <Recommendations newPrograms={newPrograms} programCollections={programCollections} />
        <Space />
      </Box>
      <AnimatedContentBlockSection description={animatedContentBlockDescription} title={animatedContentBlockTitle} />
      <Testimonials
        testimonials={testimonials}
        title='Learners Love Udacity'
        footerContent={
          <ThirdPartyReviews
            reviews={thirdPartyReviews}
            customLinkLabel={(review) => (
              <>
                {review.reviewCount} Reviews <ArrowRightIcon height='20px' width='20px' />
              </>
            )}
            onLinkClick={(_, review) =>
              trackEvent({
                name: 'Homepage CTA Clicked',
                type: 'click',
                label: 'reviews_clicked',
                value: review.company.name,
              })
            }
          />
        }
      />
      <Box mb='24px'>
        <EnterpriseContentBlockSection
          caption={blueCardContentBlockCaption}
          description={blueCardContentBlockDescription}
          image={blueCardContentBlockImage}
          title={blueCardContentBlockTitle}
          buttonsSlot={
            <>
              <TemplateButton
                {...blueCardContentBlockPrimaryCta}
                ariaLabel='Learn more about Udacity for Enterprise'
                withArrowIcon
                onClick={() =>
                  trackEvent({
                    name: 'Homepage CTA Clicked',
                    type: 'click',
                    label: blueCardContentBlockPrimaryCta.buttonLabel,
                  })
                }
              />
              {blueCardContentBlockSecondaryCta && (
                <TemplateButton
                  {...blueCardContentBlockSecondaryCta}
                  ariaLabel='Contact us about our enterprise solutions'
                  withArrowIcon
                  onClick={() =>
                    trackEvent({
                      name: 'Homepage CTA Clicked',
                      type: 'click',
                      label: blueCardContentBlockSecondaryCta.buttonLabel,
                    })
                  }
                />
              )}
            </>
          }
        />
      </Box>

      <GovernmentBannerSection description={governmentBannerDescription} title={governmentBannerTitle} />

      <Box h={{ base: '40px', xl: '120px' }} />

      <ShapesBanner
        {...homeTemplate.shapesBanner}
        onPrimaryCtaClick={() =>
          trackEvent({
            name: isFreeTrialPage ? 'Free Trial CTA Clicked' : 'Free CTA Clicked',
            type: 'click',
            label: isFreeTrialPage ? '7 day free trial' : 'start_for_free',
            value: 'CTA 2',
          })
        }
      />
    </>
  );
}
