import { memo, useRef } from 'react';

import { Box, chakra, Flex, Heading } from '@chakra-ui/react';
import { useIntersectionObserver } from '@react-hookz/web';

import { ContentContainer } from '~/components/container';
import { Markdown } from '~/components/markdown';
import { spaceHeight } from '~/components/space';

function AnimatedContentBlockSectionComponent({ title, description }: { title: string; description: string }) {
  const loadedRef = useRef(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const intersection = useIntersectionObserver(videoRef, {
    threshold: [0],
  });
  // Note: we use IntersectionObserver for lazy loading the video.
  if (intersection?.isIntersecting && !loadedRef.current) {
    const target = intersection.target as HTMLVideoElement;
    for (var source in target.children) {
      const videoSource = target.children[source] as HTMLSourceElement;
      if (typeof videoSource.tagName === 'string' && videoSource.tagName === 'SOURCE') {
        videoSource.src = videoSource.dataset.src as string;
      }
    }
    target.load();
    loadedRef.current = true;
  }

  return (
    <Box
      as='section'
      backgroundColor='white'
      padding={{
        base: '40px 0px',
        xl: '64px 0px',
      }}
    >
      <ContentContainer
        alignItems='center'
        display='flex'
        gap={spaceHeight}
        flexDirection={{
          base: 'column-reverse',
          xl: 'row',
        }}
      >
        <Box
          aria-label='Example of hands-on projects at Udacity.'
          margin='0 auto'
          maxWidth='514px'
          role='region'
          flex={{
            xl: '0 0 514px',
          }}
        >
          <chakra.video
            ref={videoRef}
            poster={`/videos/workspaces-poster.webp`}
            sx={{
              '&::-webkit-media-controls-start-playback-button': {
                display: 'none !important',
              },
            }}
            autoPlay
            controls
            loop
            muted
            playsInline
          >
            <source data-src={`/videos/workspaces.webm`} type='video/webm' />
            <source data-src={`/videos/workspaces-h265.mp4`} type='video/mp4' />
            <source data-src={`/videos/workspaces-h264.mp4`} type='video/mp4' />
          </chakra.video>
        </Box>
        <Flex flex='1' flexDirection='column' gap={{ xl: '16px' }}>
          <Heading as='h2' size='h1'>
            {title}
          </Heading>
          <Box
            h={{
              base: '40px',
              xl: '0px',
            }}
          />
          <Markdown markdownText={description} />
        </Flex>
      </ContentContainer>
    </Box>
  );
}

export const AnimatedContentBlockSection = memo(AnimatedContentBlockSectionComponent);
