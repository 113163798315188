import { useQuery } from 'react-query';

import { getPersonalizedRecommendations } from '@crossingminds/beam-react';

import { useCatalogItems } from '~/features/catalog/hooks/use-catalog-items';
import { getAnonymousIdCookie } from '~/features/cookies/utils/get-anonymous-id-cookie';
import { useClientRender } from '~/hooks/use-client-render';
import { crossingMindsConfig } from '~/libs/crossing-minds';

import { useUser } from '../../user/hooks/use-user';

export function usePopularRecommendations() {
  const { userId } = useUser();
  const clientReady = useClientRender();
  const anonymousIdCookie = getAnonymousIdCookie();

  const { data: popularRecommendations, isLoading: popularRecommendationsIsLoading } = useQuery({
    queryKey: ['popular', crossingMindsConfig, userId],
    queryFn: async () => {
      const { itemIds } = await getPersonalizedRecommendations({
        ...crossingMindsConfig,
        sessionId: anonymousIdCookie!,
        userId: userId,
        sessionScenario: 'home_most_pop',
        userScenario: 'home_most_pop',
      });

      return itemIds;
    },
    enabled: clientReady && Boolean(anonymousIdCookie),
  });

  const {
    catalogItems: popularRecommendationCatalogItems,
    catalogItemsIsLoading: popularRecommendationCatalogItemsIsLoading,
  } = useCatalogItems(popularRecommendations);

  return {
    popularRecommendations,
    popularRecommendationsIsLoading,
    popularRecommendationCatalogItems,
    popularRecommendationCatalogItemsIsLoading,
    isLoading: popularRecommendationsIsLoading || popularRecommendationCatalogItemsIsLoading,
  };
}
