import { useQuery } from 'react-query';

import { getPersonalizedRecommendations } from '@crossingminds/beam-react';

import { useCatalogItems } from '~/features/catalog/hooks/use-catalog-items';
import { getAnonymousIdCookie } from '~/features/cookies/utils/get-anonymous-id-cookie';
import { useClientRender } from '~/hooks/use-client-render';
import { crossingMindsConfig } from '~/libs/crossing-minds';

import { useUser } from '../../user/hooks/use-user';

export function usePersonalizedRecommendations() {
  const { userId } = useUser();
  const clientReady = useClientRender();
  const anonymousIdCookie = getAnonymousIdCookie();

  const { data: personalizedRecommendations, isLoading: personalizedRecommendationsIsLoading } = useQuery({
    queryKey: ['personalized', crossingMindsConfig, userId],
    queryFn: async () => {
      const { itemIds } = await getPersonalizedRecommendations({
        ...crossingMindsConfig,
        sessionId: anonymousIdCookie!,
        userId: userId,
        sessionScenario: 'home_recommended_for_you',
        userScenario: 'home_recommended_for_you',
      });

      return itemIds;
    },
    enabled: clientReady && Boolean(anonymousIdCookie),
  });

  const {
    catalogItems: personalizedRecommendationCatalogItems,
    catalogItemsIsLoading: personalizedRecommendationCatalogItemsIsLoading,
  } = useCatalogItems(personalizedRecommendations);

  return {
    personalizedRecommendations,
    personalizedRecommendationsIsLoading,
    personalizedRecommendationCatalogItems,
    personalizedRecommendationCatalogItemsIsLoading,
    isLoading: personalizedRecommendationsIsLoading || personalizedRecommendationCatalogItemsIsLoading,
  };
}
