import { ReactNode } from 'react';

import { Box, chakra, Flex, Heading, List, ListItem, Text } from '@chakra-ui/react';

import { Card } from '~/components/card';
import { ContentContainer } from '~/components/container';
import { Glyph } from '~/components/glyph';

export type TestimonialsProps = {
  title: string;
  testimonials: Array<{
    _id: string;
    _type: 'testimonial';
    quote: string;
    glyphName: string;
    name: string;
  }>;
  footerContent?: ReactNode;
};

export function Testimonials({ testimonials, title, footerContent = null }: TestimonialsProps) {
  return (
    <Box as='section'>
      <ContentContainer paddingBottom='40px' paddingTop='40px'>
        <Heading as='h2' size='h2'>
          {title}
        </Heading>
        <Box height='16px' />
        <List
          display='flex'
          gap='32px'
          flexDirection={{
            base: 'column',
            md: 'row',
          }}
        >
          {testimonials.map((testimonial) => (
            <ListItem key={testimonial._id} flex='1'>
              <Card
                as='article'
                borderBottomRightRadius='xl'
                display='flex'
                flexDirection='column'
                height='100%'
                justifyContent='center'
                padding='40px 24px'
              >
                <Flex flexDirection='column' gap='24px'>
                  <chakra.blockquote>
                    <Text
                      sx={{
                        '&::before': {
                          content: '"\\201C"',
                        },
                        '&::after': {
                          content: '"\\201D"',
                        },
                      }}
                    >
                      {testimonial.quote}
                    </Text>
                  </chakra.blockquote>
                  <Flex alignItems='center' fontWeight='600' gap='8px'>
                    {testimonial.glyphName && <Glyph height={32} name={testimonial.glyphName} width={32} />}
                    {testimonial.name}
                  </Flex>
                </Flex>
              </Card>
            </ListItem>
          ))}
        </List>
        {footerContent && (
          <>
            <Box height='48px' />
            {footerContent}
          </>
        )}
      </ContentContainer>
    </Box>
  );
}
