import { MouseEvent, ReactNode } from 'react';

import { Box, Flex, List, ListItem } from '@chakra-ui/react';

import { ThirdPartyReview, ThirdPartyReviewContentList } from '~/features/third-party-reviews/model';

import { Link } from './link';
import { Rating } from './rating';
import { SanityImage } from './sanity-image';

export function ThirdPartyReviews({
  reviews,
  customLinkLabel,
  onLinkClick,
  showLinkToReview = true,
}: {
  reviews: ThirdPartyReviewContentList;
  customLinkLabel?: (review: ThirdPartyReview) => ReactNode | string | number;
  onLinkClick?: (e: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>, review: ThirdPartyReview) => void;
  showLinkToReview?: boolean;
}) {
  return (
    <Box>
      <List
        display='flex'
        flexWrap='wrap'
        justifyContent='center'
        gap={{
          base: '32px 48px',
          md: '56px',
        }}
      >
        {reviews.map((review) => {
          return (
            <ListItem key={review.company.name}>
              <Box>
                <Box marginBottom='4px'>
                  <SanityImage alt={review.company.name} image={review.company.lofiLogoDark} />
                </Box>
                <Box>
                  <Flex alignItems='center' gap='8px'>
                    <Rating color='blue.500' rating={review.rating} size='sm' />
                  </Flex>
                  {showLinkToReview && review.url && (
                    <Link
                      alignItems='center'
                      aria-label={`${review.reviewCount} ${review.company.name} reviews`}
                      display='inline-flex'
                      gap='4px'
                      href={review.url}
                      whiteSpace='nowrap'
                      isExternal
                      onClick={(e) => onLinkClick?.(e, review)}
                    >
                      {customLinkLabel?.(review)}
                    </Link>
                  )}
                </Box>
              </Box>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
}
