import { Flex } from '@chakra-ui/react';

import { trackEvent } from '~/features/analytics/track-event';
import { CatalogCardCarousel } from '~/features/catalog/components/catalog-card-carousel';
import { CatalogItem } from '~/features/catalog/models/catalog-item';
import { useAiRecommendations } from '~/features/program/hooks/use-ai-recommendations';
import { useIn30DaysRecommendations } from '~/features/program/hooks/use-in-30-days-recommendations';
import { usePersonalizedRecommendations } from '~/features/program/hooks/use-personalized-recommendations';
import { usePopularRecommendations } from '~/features/program/hooks/use-popular-recommendations';

import { ProgramCollectionWithCatalogData } from './home';

function PersonalizedRecommendations() {
  const { personalizedRecommendationCatalogItems, isLoading: personalizedRecommendationsIsLoading } =
    usePersonalizedRecommendations();
  return (
    <CatalogCardCarousel
      buttonContext='courses recommended for you'
      catalogItems={personalizedRecommendationCatalogItems}
      isLoading={personalizedRecommendationsIsLoading}
      title='Courses Recommended for You'
      titleAs='h2'
      titleSize='h4'
      onCardClick={(catalogItem) => {
        trackEvent({
          type: 'click',
          name: 'Homepage CTA Clicked',
          label: 'Courses Recommended for You',
          value: catalogItem.title ?? '',
          action: 'homepage_ndcard_clicked',
        });
      }}
      onNextOrPrevClick={() => {
        trackEvent({
          type: 'click',
          name: 'Homepage CTA Clicked',
          label: 'Courses Recommended for You',
          action: 'homepage_carouselnavbutton_clicked',
        });
      }}
    />
  );
}

function PopularRecommendations() {
  const { popularRecommendationCatalogItems, isLoading: mostPopularRecommendationsIsLoading } =
    usePopularRecommendations();
  return (
    <CatalogCardCarousel
      buttonContext='most popular courses'
      catalogItems={popularRecommendationCatalogItems}
      isLoading={mostPopularRecommendationsIsLoading}
      title='Most Popular Courses'
      titleAs='h2'
      titleSize='h4'
      onCardClick={(catalogItem) => {
        trackEvent({
          type: 'click',
          name: 'Homepage CTA Clicked',
          label: 'Most Popular Courses',
          value: catalogItem.title ?? '',
          action: 'homepage_ndcard_clicked',
        });
      }}
      onNextOrPrevClick={() => {
        trackEvent({
          type: 'click',
          name: 'Homepage CTA Clicked',
          label: 'Most Popular Courses',
          action: 'homepage_carouselnavbutton_clicked',
        });
      }}
    />
  );
}

function TopAISkillsRecommendations() {
  const { aiRecommendationCatalogItems, isLoading: aiRecommendationsIsLoading } = useAiRecommendations();
  return (
    <CatalogCardCarousel
      buttonContext='top AI & data skills courses'
      catalogItems={aiRecommendationCatalogItems}
      isLoading={aiRecommendationsIsLoading}
      title='Top AI & Data Skills Courses'
      titleAs='h2'
      titleSize='h4'
      onCardClick={(catalogItem) => {
        trackEvent({
          type: 'click',
          name: 'Homepage CTA Clicked',
          label: 'Top AI & Data Skills Courses',
          value: catalogItem.title ?? '',
          action: 'homepage_ndcard_clicked',
        });
      }}
      onNextOrPrevClick={() => {
        trackEvent({
          type: 'click',
          name: 'Homepage CTA Clicked',
          label: 'Top AI & Data Skills Courses',
          action: 'homepage_carouselnavbutton_clicked',
        });
      }}
    />
  );
}

function In30DaysRecommendations() {
  const { in30DaysRecommendationCatalogItems, isLoading: in30DaysRecommendationsIsLoading } =
    useIn30DaysRecommendations();
  return (
    <CatalogCardCarousel
      buttonContext='courses to learn new skills in 30 days'
      catalogItems={in30DaysRecommendationCatalogItems}
      isLoading={in30DaysRecommendationsIsLoading}
      title='Courses to Learn New Skills in 30 Days'
      titleAs='h2'
      titleSize='h4'
      onCardClick={(catalogItem) => {
        trackEvent({
          type: 'click',
          name: 'Homepage CTA Clicked',
          label: 'Courses to Learn New Skills in 30 Days',
          value: catalogItem.title ?? '',
          action: 'homepage_ndcard_clicked',
        });
      }}
      onNextOrPrevClick={() => {
        trackEvent({
          type: 'click',
          name: 'Homepage CTA Clicked',
          label: 'Courses to Learn New Skills in 30 Days',
          action: 'homepage_carouselnavbutton_clicked',
        });
      }}
    />
  );
}

function ProgramCollection({
  title,
  buttonContext,
  programs,
}: {
  title: string;
  buttonContext: string;
  programs: CatalogItem[];
}) {
  return (
    <CatalogCardCarousel
      buttonContext={buttonContext}
      catalogItems={programs}
      isLoading={false}
      title={title}
      titleAs='h2'
      titleSize='h4'
      onCardClick={(catalogItem) => {
        trackEvent({
          type: 'click',
          name: 'Homepage CTA Clicked',
          label: title,
          value: catalogItem.title ?? '',
          action: 'homepage_ndcard_clicked',
        });
      }}
      onNextOrPrevClick={() => {
        trackEvent({
          type: 'click',
          name: 'Homepage CTA Clicked',
          label: title,
          action: 'homepage_carouselnavbutton_clicked',
        });
      }}
    />
  );
}

export function Recommendations({
  newPrograms,
  programCollections,
}: {
  newPrograms: ProgramCollectionWithCatalogData;
  programCollections: ProgramCollectionWithCatalogData[];
}) {
  return (
    <Flex flexDirection='column' gap='40px'>
      <PersonalizedRecommendations />
      {!!newPrograms.programs.length && (
        <ProgramCollection
          buttonContext='newly released courses'
          programs={newPrograms.programs}
          title={newPrograms.title}
        />
      )}

      <PopularRecommendations />
      <TopAISkillsRecommendations />
      <In30DaysRecommendations />

      {programCollections.map(
        (programCollection) =>
          !!programCollection.programs?.length && (
            <ProgramCollection
              key={programCollection.title}
              buttonContext={programCollection.title}
              programs={programCollection.programs}
              title={programCollection.title}
            />
          )
      )}
    </Flex>
  );
}
