import { useQuery } from 'react-query';

import { getCatalogItems } from '../controllers/get-catalog-items';

export function useCatalogItems(programKeys: string[] | undefined) {
  const {
    data: catalogItems,
    isLoading: catalogItemsIsLoading,
    isFetched: catalogItemsIsFetched,
  } = useQuery(['catalogItems', programKeys], () => getCatalogItems(programKeys!), {
    enabled: Boolean(programKeys && programKeys.length > 0),
  });

  return {
    catalogItems,
    catalogItemsIsLoading,
    catalogItemsIsFetched,
  };
}
