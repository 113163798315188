import { ReactNode, useState } from 'react';
import Image from 'next/image';

import { Box, Flex, Heading, IconButton, keyframes, Text } from '@chakra-ui/react';
import { ViewOffIcon, ViewOnIcon } from '@udacity/chakra-uds-icons';

import { ShapesBanner as ShapesBannerType } from '~/features/page-template/models/shapes-banner';

import { ContentContainer } from './container';
import { TemplateButton } from './template-button';

const slideUp = keyframes`
  from {transform: translate(-50%, 0%); top: 100%}
  to {transform: translate(-50%, -100%); top: 0%}
`;

export function ShapesBanner({
  title,
  subTitle,
  buttonSlot,
  primaryCta,
  secondaryCta,
  flipTitleAndSubtitleOrder,
  onPrimaryCtaClick,
  onSecondaryCtaClick,
}: ShapesBannerType & {
  buttonSlot?: ReactNode;
  flipTitleAndSubtitleOrder?: boolean;
  onPrimaryCtaClick?: () => void;
  onSecondaryCtaClick?: () => void;
}) {
  const [isAnimationPaused, setIsAnimationPaused] = useState(false);

  return (
    <Flex
      alignItems='center'
      as='section'
      bg='blue.800'
      color='white'
      flexWrap='wrap'
      justifyContent='center'
      overflow='hidden'
      paddingTop={{ base: '64px', md: '108px' }}
      position='relative'
      textAlign='center'
    >
      <Box
        animation={`${slideUp} 20000ms infinite linear`}
        height={{ base: 353, lg: 833 }}
        left='50%'
        position='absolute'
        sx={{ 'animation-play-state': isAnimationPaused ? 'paused' : 'running' }}
        top='100%'
        transform='translate(-50%, 0%)'
        width={{ base: 548, lg: 1440 }}
      >
        <Image alt='' sizes='(min-width: 992px) 1440px, 548px' src='/images/backgrounds/blue-shapes.png' fill />
      </Box>
      <ContentContainer position='relative' zIndex={2}>
        <Flex flexDirection={flipTitleAndSubtitleOrder ? 'column-reverse' : 'column'} gap='8px' justifyContent='center'>
          <Text margin='auto' maxWidth='900px'>
            {subTitle}
          </Text>

          <Heading as='h2' margin='auto' maxWidth='900px' size='h1'>
            {title}
          </Heading>
        </Flex>

        <Box h='16px' />
        <Flex gap='16px' justifyContent='center'>
          {buttonSlot && buttonSlot}
          {primaryCta && <TemplateButton {...primaryCta} withArrowIcon={true} onClick={onPrimaryCtaClick} />}
          {secondaryCta && <TemplateButton {...secondaryCta} onClick={onSecondaryCtaClick} />}
        </Flex>
      </ContentContainer>

      <Box
        display='flex'
        justifyContent='flex-end'
        paddingBottom='16px'
        paddingTop={{ base: 'calc(64px - 16px - 24px)', md: 'calc(108px - 16px - 24px)' }}
        paddingX='16px'
        width='100%'
      >
        {isAnimationPaused ? (
          <IconButton
            aria-label='Play Animation'
            colorScheme='white'
            height='24px'
            icon={<ViewOffIcon height='24px' width='24px' />}
            width='24px'
            onClick={() => setIsAnimationPaused(false)}
          />
        ) : (
          <IconButton
            aria-label='Pause Animation'
            colorScheme='white'
            height='24px'
            icon={<ViewOnIcon height='24px' width='24px' />}
            width='24px'
            onClick={() => setIsAnimationPaused(true)}
          />
        )}
      </Box>
    </Flex>
  );
}
