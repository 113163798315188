import { ReactNode } from 'react';

import { Box, Flex, Heading, Text } from '@chakra-ui/react';

import { Card } from '~/components/card';
import { ContentContainer } from '~/components/container';
import { Markdown } from '~/components/markdown';
import { SanityImage } from '~/components/sanity-image';
import { SanityImageType } from '~/features/page-template/models/image';

export function EnterpriseContentBlockSection({
  title,
  description,
  caption,
  image,
  buttonsSlot = null,
}: {
  title: string;
  description: string;
  caption?: string | null;
  image: SanityImageType;
  buttonsSlot?: ReactNode;
}) {
  return (
    <ContentContainer as='section' padding='unset'>
      <Card
        alignItems='center'
        backgroundColor='accent.sky.100'
        borderRadius={{ base: 'unset', lg: 'base' }}
        display='flex'
        gap={{ lg: '40px' }}
        padding={{ base: '40px 16px', lg: '64px' }}
      >
        <Box flex={{ base: '1', lg: '0 0 408px' }}>
          <Heading as='h2' fontWeight='500' size='marketing-4xl'>
            {title}
          </Heading>
          <Text fontWeight='500'>{caption}</Text>
          <Box h='24px' />
          {/* Mobile Image */}
          <Box display={{ base: 'block', lg: 'none' }}>
            <SanityImage
              height={344}
              image={image}
              width={542}
              style={{
                objectFit: 'contain',
                width: '100%',
              }}
            />
            <Box h='24px' />
          </Box>
          <Markdown markdownText={description} />
          <Box h='24px' />
          <Flex flexWrap='wrap' gap='8px'>
            {buttonsSlot}
          </Flex>
        </Box>
        {/* Desktop Image */}
        <Box display={{ base: 'none', lg: 'block' }}>
          <SanityImage height={402} image={image} width={544} />
        </Box>
      </Card>
    </ContentContainer>
  );
}
