import { useQuery } from 'react-query';

import { getPersonalizedRecommendations } from '@crossingminds/beam-react';

import { useCatalogItems } from '~/features/catalog/hooks/use-catalog-items';
import { getAnonymousIdCookie } from '~/features/cookies/utils/get-anonymous-id-cookie';
import { useClientRender } from '~/hooks/use-client-render';
import { crossingMindsConfig } from '~/libs/crossing-minds';

import { useUser } from '../../user/hooks/use-user';

export function useAiRecommendations() {
  const { userId } = useUser();
  const clientReady = useClientRender();
  const anonymousIdCookie = getAnonymousIdCookie();

  const { data: aiRecommendations, isLoading: aiRecommendationsIsLoading } = useQuery({
    queryKey: ['ai', crossingMindsConfig, userId],
    queryFn: async () => {
      const { itemIds } = await getPersonalizedRecommendations({
        ...crossingMindsConfig,
        sessionId: anonymousIdCookie!,
        userId: userId,
        sessionScenario: 'home_top_ai_skills',
        userScenario: 'home_top_ai_skills',
      });

      return itemIds;
    },
    enabled: clientReady && Boolean(anonymousIdCookie),
  });

  const { catalogItems: aiRecommendationCatalogItems, catalogItemsIsLoading: aiRecommendationCatalogItemsIsLoading } =
    useCatalogItems(aiRecommendations);

  return {
    aiRecommendations,
    aiRecommendationsIsLoading,
    aiRecommendationCatalogItems,
    aiRecommendationCatalogItemsIsLoading,
    isLoading: aiRecommendationsIsLoading || aiRecommendationCatalogItemsIsLoading,
  };
}
