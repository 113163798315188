import { useQuery } from 'react-query';

import { getPersonalizedRecommendations } from '@crossingminds/beam-react';

import { useCatalogItems } from '~/features/catalog/hooks/use-catalog-items';
import { getAnonymousIdCookie } from '~/features/cookies/utils/get-anonymous-id-cookie';
import { useClientRender } from '~/hooks/use-client-render';
import { crossingMindsConfig } from '~/libs/crossing-minds';

import { useUser } from '../../user/hooks/use-user';

export function useIn30DaysRecommendations() {
  const { userId } = useUser();
  const clientReady = useClientRender();
  const anonymousIdCookie = getAnonymousIdCookie();

  const { data: in30DaysRecommendations, isLoading: in30DaysRecommendationsIsLoading } = useQuery({
    queryKey: ['in30Days', crossingMindsConfig, userId],
    queryFn: async () => {
      const { itemIds } = await getPersonalizedRecommendations({
        ...crossingMindsConfig,
        sessionId: anonymousIdCookie!,
        userId: userId,
        sessionScenario: 'home_in_30_days',
        userScenario: 'home_in_30_days',
      });

      return itemIds;
    },
    enabled: clientReady && Boolean(anonymousIdCookie),
  });

  const {
    catalogItems: in30DaysRecommendationCatalogItems,
    catalogItemsIsLoading: in30DaysRecommendationCatalogItemsIsLoading,
  } = useCatalogItems(in30DaysRecommendations);

  return {
    in30DaysRecommendations,
    in30DaysRecommendationsIsLoading,
    in30DaysRecommendationCatalogItems,
    in30DaysRecommendationCatalogItemsIsLoading,
    isLoading: in30DaysRecommendationsIsLoading || in30DaysRecommendationCatalogItemsIsLoading,
  };
}
