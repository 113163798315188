import { useRouter } from 'next/router';

export function useExperiment() {
  const { pathname, query } = useRouter();

  // Note: If the pathname does not start with "/test", it is not an experiment page.
  if (!pathname.startsWith('/test')) {
    return null;
  }

  let [, , key, variant] = pathname.split('/'); // Example: "/test/EXPERIMENT_KEY/VARIANT_KEY"

  // Note: Handle case when variant key is dynamic - "/test/EXPERIMENT_KEY/[variantKey]"
  const hasDynamicVariant = variant?.match(/\[([a-zA-Z0-9_]+)\]/); // Note: "[variantKey]" => "variantKey"
  if (hasDynamicVariant) {
    const key = hasDynamicVariant[1];
    variant = query[key] as string;
  }

  return { [key]: variant };
}
