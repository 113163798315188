import Image from 'next/image';

import { Box } from '@chakra-ui/react';

export function DecorativeWave({ color }: { color: 'blue' | 'green' }) {
  return (
    <>
      {color === 'blue' && (
        <Box
          height='1557px'
          left='50%'
          pointerEvents='none'
          position='absolute'
          top={0}
          transform='translate(-116%, -150px)'
          width='1096px'
          display={{
            base: 'none',
            xl: 'block',
          }}
        >
          <Image alt='' loading='lazy' sizes='1096px' src='/images/backgrounds/blue-u.png' fill />
        </Box>
      )}
      {color === 'green' && (
        <Box
          bottom={0}
          height='1180px'
          left='50%'
          pointerEvents='none'
          position='absolute'
          width='984px'
          display={{
            base: 'none',
            xl: 'block',
          }}
        >
          <Image alt='' loading='lazy' sizes='984px' src='/images/backgrounds/green-u.png' fill />
        </Box>
      )}
    </>
  );
}
