import { logger } from '~/features/logging/logger';

import { CatalogItem } from '../models/catalog-item';

export async function getCatalogItems(keys: string[]): Promise<CatalogItem[]> {
  try {
    const response = await fetch(`/api/get-catalog-item?keys=${keys.join(',')}`);

    const data = await response.json();

    if (!response.ok) {
      throw new Error('Error fetching catalog items');
    }

    return data as CatalogItem[];
  } catch (error) {
    logger.error('Error fetching catalog items', {
      file: 'src/features/catalog/controllers/get-catalog-items.ts',
      function: 'getCatalogItems',
      keys: keys,
      error,
    });

    return [];
  }
}
